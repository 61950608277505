<script>
  import LogoPayPal from '@/images/logos/LogoPayPal.svg'

  /**
   * A representation of a customer’s billing information (credit card or PayPal).
   */
  export default {
    components: {
      LogoPayPal,
    },
    props: {
      /**
       * Billing info object with the following properties (all strings):
       * `type`
       *
       * + if `type` is `credit-card`:
       * `cardType`,
       * `cardNumber`,
       * `month`,
       * `year`,
       */
      billingInfo: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<template>
  <div>
    <template v-if="billingInfo.type === 'credit-card'">
      {{ billingInfo.cardType }} ending in {{ billingInfo.cardNumber.substr(-4) }}<br />
      <span>Expires {{ billingInfo.month }}/{{ billingInfo.year }}</span>
    </template>

    <template v-if="billingInfo.type === 'paypal'">
      <LogoPayPal class="mt-1 w-20 h-5" />
    </template>
  </div>
</template>
